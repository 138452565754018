import { View, Text, Dimensions, FlatList, TextInput, Image, ScrollView, TouchableOpacity } from 'react-native'
import React, {useState, useEffect} from 'react'
import { Feather, Ionicons } from '@expo/vector-icons'; 
import { useNavigation } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries'


const {width, height} = Dimensions.get('window')

const UsersScreen = () => {

    const navigation = useNavigation()

    const [selectedUser, setSelectedUser] = useState([])

    const [alldevided, setAlldevices] = useState(true)

    const [UserList, setUserList] = useState([])


    async function fetchUsers() {

        const allTodos = await API.graphql({ query: queries.listUserData});

        const todos = allTodos.data.listUserData.items
        setUserList(todos)


        console.log('items :', todos)

     }

     useEffect(() => {
      fetchUsers()

    }, [])






    const seperator = ()=> {
        return(
            <View style={{height: 1, backgroundColor: '#d1d1d1'}}/>
        )
    }

    

    if(alldevided){

        const renderUsers = ({item})=> {
            return(
                <TouchableOpacity onPress={()=> navigation.navigate('UserDetails', {userDet: item})} style={{marginLeft: 15, height: 100, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={{width: 50, height: 50}} source={require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/>
                        <View style={{marginLeft: 25}}>
                            <Text>{item?.name} {item?.surname}</Text>
                            <Text>{item?.email}</Text>
                        </View>
                        
                    </View>

                    <View style={{marginRight: 30}}>
                        <Ionicons name="md-arrow-forward-circle-sharp" size={24} color="black" />
                    </View>
                    
                </TouchableOpacity>
            )
        }

        return(
            <View style={{flex: 1, backgroundColor: 'white'}}>

                <View style={{flexDirection: 'row',}}>

                

                    <View style={{width: '100%'}}>
                        
                    

                    <View style={{margin: 15, marginTop: 15, flexDirection: 'row', borderWidth: 0, borderColor: 'black', borderRadius: 20, justifyContent: 'center'}}>

                        <View style={{borderWidth: 1, borderColor: 'gray', borderRadius: 20, width: 300, height: 40, flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{height: 30, width: 30, marginLeft: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 50, backgroundColor: '#d1d1d1'}}>
                                <Feather name="search" size={24} color="black" />
                            </View>

                            <View style={{marginLeft: 10}}>
                                <TextInput placeholder='siso@sundaycreatives.co.za' style={{width: 250}} />
                            </View>
                        </View>

                        

                    </View>

                
                    <FlatList
                        scrollEnabled={true}
                        data={UserList}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={renderUsers} 
                        style={{height: 400}}
                        ItemSeparatorComponent={seperator}

                    />
                  

                    </View>

                   

                </View>
            </View>
        )


    }



  return (
    <View>
      <Text>index</Text>
    </View>
  )
}

export default UsersScreen