/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCartProductUpdatedd = /* GraphQL */ `
  subscription OnCartProductUpdatedd {
    onCartProductUpdatedd {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const onCartProductDeleted = /* GraphQL */ `
  subscription OnCartProductDeleted {
    onCartProductDeleted {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const onCartProductAdded = /* GraphQL */ `
  subscription OnCartProductAdded {
    onCartProductAdded {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const onOrderAdded = /* GraphQL */ `
  subscription OnOrderAdded {
    onOrderAdded {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const onOrderUpdated = /* GraphQL */ `
  subscription OnOrderUpdated {
    onOrderUpdated {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const onUserUpdated = /* GraphQL */ `
  subscription OnUserUpdated {
    onUserUpdated {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const onUserAdded = /* GraphQL */ `
  subscription OnUserAdded {
    onUserAdded {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedNotifications = /* GraphQL */ `
  subscription OnUpdatedNotifications {
    onUpdatedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedNotifications = /* GraphQL */ `
  subscription OnCreatedNotifications {
    onCreatedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedNotifications = /* GraphQL */ `
  subscription OnDeletedNotifications {
    onDeletedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedMessages = /* GraphQL */ `
  subscription OnUpdatedMessages {
    onUpdatedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedMessages = /* GraphQL */ `
  subscription OnCreatedMessages {
    onCreatedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedMessages = /* GraphQL */ `
  subscription OnDeletedMessages {
    onDeletedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onAddedDriver = /* GraphQL */ `
  subscription OnAddedDriver {
    onAddedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedDriver = /* GraphQL */ `
  subscription OnUpdatedDriver {
    onUpdatedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedDriver = /* GraphQL */ `
  subscription OnDeletedDriver {
    onDeletedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onCatsAdded = /* GraphQL */ `
  subscription OnCatsAdded {
    onCatsAdded {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onCatsUpdated = /* GraphQL */ `
  subscription OnCatsUpdated {
    onCatsUpdated {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onCatsDeleted = /* GraphQL */ `
  subscription OnCatsDeleted {
    onCatsDeleted {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onAddedShopRequest = /* GraphQL */ `
  subscription OnAddedShopRequest {
    onAddedShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedShopRequest = /* GraphQL */ `
  subscription OnUpdatedShopRequest {
    onUpdatedShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedShopRequest = /* GraphQL */ `
  subscription OnDeletedShopRequest {
    onDeletedShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onShopAdded = /* GraphQL */ `
  subscription OnShopAdded {
    onShopAdded {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onShopUpdated = /* GraphQL */ `
  subscription OnShopUpdated {
    onShopUpdated {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onShopDeleted = /* GraphQL */ `
  subscription OnShopDeleted {
    onShopDeleted {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onCreateBlog = /* GraphQL */ `
  subscription OnCreateBlog {
    onCreateBlog {
      id
      name
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBlog = /* GraphQL */ `
  subscription OnUpdateBlog {
    onUpdateBlog {
      id
      name
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBlog = /* GraphQL */ `
  subscription OnDeleteBlog {
    onDeleteBlog {
      id
      name
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      post {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      post {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      post {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const onCreateUserData = /* GraphQL */ `
  subscription OnCreateUserData {
    onCreateUserData {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserData = /* GraphQL */ `
  subscription OnUpdateUserData {
    onUpdateUserData {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserData = /* GraphQL */ `
  subscription OnDeleteUserData {
    onDeleteUserData {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      discount
      DaySpecial
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      discount
      DaySpecial
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      discount
      DaySpecial
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductOD = /* GraphQL */ `
  subscription OnCreateProductOD {
    onCreateProductOD {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      promoCat
      discount
      promoted
      active
      price
      oldPrice
      filterCat
      filterCatB
      isle
      group
      category
      subCategory
      brand
      quantity
      available
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductOD = /* GraphQL */ `
  subscription OnUpdateProductOD {
    onUpdateProductOD {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      promoCat
      discount
      promoted
      active
      price
      oldPrice
      filterCat
      filterCatB
      isle
      group
      category
      subCategory
      brand
      quantity
      available
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductOD = /* GraphQL */ `
  subscription OnDeleteProductOD {
    onDeleteProductOD {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      promoCat
      discount
      promoted
      active
      price
      oldPrice
      filterCat
      filterCatB
      isle
      group
      category
      subCategory
      brand
      quantity
      available
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCartProduct = /* GraphQL */ `
  subscription OnCreateCartProduct {
    onCreateCartProduct {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCartProduct = /* GraphQL */ `
  subscription OnUpdateCartProduct {
    onUpdateCartProduct {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCartProduct = /* GraphQL */ `
  subscription OnDeleteCartProduct {
    onDeleteCartProduct {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderProduct = /* GraphQL */ `
  subscription OnCreateOrderProduct {
    onCreateOrderProduct {
      id
      quantity
      option
      price
      image
      title
      titilet
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderProduct = /* GraphQL */ `
  subscription OnUpdateOrderProduct {
    onUpdateOrderProduct {
      id
      quantity
      option
      price
      image
      title
      titilet
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderProduct = /* GraphQL */ `
  subscription OnDeleteOrderProduct {
    onDeleteOrderProduct {
      id
      quantity
      option
      price
      image
      title
      titilet
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageList = /* GraphQL */ `
  subscription OnCreateMessageList {
    onCreateMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageList = /* GraphQL */ `
  subscription OnUpdateMessageList {
    onUpdateMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageList = /* GraphQL */ `
  subscription OnDeleteMessageList {
    onDeleteMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationsList = /* GraphQL */ `
  subscription OnCreateNotificationsList {
    onCreateNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationsList = /* GraphQL */ `
  subscription OnUpdateNotificationsList {
    onUpdateNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationsList = /* GraphQL */ `
  subscription OnDeleteNotificationsList {
    onDeleteNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDriverList = /* GraphQL */ `
  subscription OnCreateDriverList {
    onCreateDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDriverList = /* GraphQL */ `
  subscription OnUpdateDriverList {
    onUpdateDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDriverList = /* GraphQL */ `
  subscription OnDeleteDriverList {
    onDeleteDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCategoryList = /* GraphQL */ `
  subscription OnCreateCategoryList {
    onCreateCategoryList {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategoryList = /* GraphQL */ `
  subscription OnUpdateCategoryList {
    onUpdateCategoryList {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCategoryList = /* GraphQL */ `
  subscription OnDeleteCategoryList {
    onDeleteCategoryList {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBannerList = /* GraphQL */ `
  subscription OnCreateBannerList {
    onCreateBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBannerList = /* GraphQL */ `
  subscription OnUpdateBannerList {
    onUpdateBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBannerList = /* GraphQL */ `
  subscription OnDeleteBannerList {
    onDeleteBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReturnProduct = /* GraphQL */ `
  subscription OnCreateReturnProduct {
    onCreateReturnProduct {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReturnProduct = /* GraphQL */ `
  subscription OnUpdateReturnProduct {
    onUpdateReturnProduct {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReturnProduct = /* GraphQL */ `
  subscription OnDeleteReturnProduct {
    onDeleteReturnProduct {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderReport = /* GraphQL */ `
  subscription OnCreateOrderReport {
    onCreateOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderReport = /* GraphQL */ `
  subscription OnUpdateOrderReport {
    onUpdateOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderReport = /* GraphQL */ `
  subscription OnDeleteOrderReport {
    onDeleteOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateShop = /* GraphQL */ `
  subscription OnCreateShop {
    onCreateShop {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onUpdateShop = /* GraphQL */ `
  subscription OnUpdateShop {
    onUpdateShop {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onDeleteShop = /* GraphQL */ `
  subscription OnDeleteShop {
    onDeleteShop {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onCreateShopRequest = /* GraphQL */ `
  subscription OnCreateShopRequest {
    onCreateShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateShopRequest = /* GraphQL */ `
  subscription OnUpdateShopRequest {
    onUpdateShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteShopRequest = /* GraphQL */ `
  subscription OnDeleteShopRequest {
    onDeleteShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
