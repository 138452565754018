import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, Feather, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onUpdateDriverList, onUpdatedDriver, onDeletedDriver } from '../../graphql/subscriptions';
import { onUpdateUserData, onDeleteUserData, onCreateUserData } from '../../graphql/subscriptions';

const NewDriver = () => {

    const navigation = useNavigation();
    const mainColour = '#FF4F00';

    const [UserList, setUserList] = useState([])
    const [updUser, setUpdUser] = useState([])


    async function fetchUsers() {

        const allTodos = await API.graphql({ query: queries.listUserData});

        const todos = allTodos.data.listUserData.items
        setUserList(todos)


        console.log('items :', todos)

     }


      useEffect(() => {
      fetchUsers()

    }, [updUser])


    


    const renderUsers = ({item})=> {

        if(!item?.driver === true){

            return(
                <View  style={{marginLeft: 15, height: 100, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={{width: 50, height: 50}} source={require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/>
                        <View style={{marginLeft: 25}}>
                            <Text>{item?.name} {item?.surname}</Text>
                            <Text>{item?.email}</Text>
                        </View>
                    </View>

                    <View style={{marginRight: 30}}>
                         <TouchableOpacity onPress={()=> navigation.navigate('AddDriver', {id: item?.id, name: item?.name, surname: item?.surname, image: item?.image, plate: item?.plate})} style={{height: 40, width: 100, borderRadius: 5, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{color: 'white'}}>Make Driver</Text>
                        </TouchableOpacity>
                    </View>
                    
                </View>
            )
        }
        }

        const seperator = ()=> {
        return(
            <View style={{height: 1, backgroundColor: '#d1d1d1'}}/>
        )
    }


        let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onUpdateUserData)).subscribe( {next: (daraa) => {
         setUpdUser(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onDeleteUserData)).subscribe( {next: (daraaa) => {
         setUpdUser(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onCreateUserData)).subscribe( {next: (daraaaaa) => {
         setUpdUser(daraaaaa)
     }, }) 

    }

    useEffect(() =>{
        setUpSus();

        return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };

    },[]);


  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>


        </View>

        <View style={{marginTop: 15, borderTopWidth: 1, borderColor: '#d1d1d1', width: '100%'}}>
            <View style={{margin: 15}}>
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Add New Driver</Text>

            </View>
        </View>

        <View style={{ width: '100%'}}>

            {/* <View style={{margin: 15, marginTop: 0, flexDirection: 'row', borderWidth: 0, borderColor: 'black', borderRadius: 20, justifyContent: 'center'}}>

                        <View style={{borderWidth: 1, borderColor: 'gray', borderRadius: 20, width: 300, height: 40, flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{height: 30, width: 30, marginLeft: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 50, backgroundColor: '#d1d1d1'}}>
                                <Feather name="search" size={24} color="black" />
                            </View>

                            <View style={{marginLeft: 10}}>
                                <TextInput placeholder='siso@sundaycreatives.co.za' style={{width: 250}} />
                            </View>
                        </View>

                        

                    </View> */}
            {/* image */}
            <View style={{margin: 15, marginTop: 25, width: '100%'}}>
                <FlatList
                        scrollEnabled={true}
                        data={UserList}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={renderUsers} 
                        style={{height: 400, width: '100%'}}
                        ItemSeparatorComponent={seperator}

                    />
            </View>


        </View>
    </View>
  )
}

export default NewDriver