import React, {useState} from 'react';
import { View } from 'react-native';
import { createStackNavigator} from '@react-navigation/stack';
import StockMain from '../Screens/StockScreen/StockMain';
import CategoryScreen from '../Screens/StockScreen/CategoryScreen';
import StoreScreen from '../Screens/StoreScreen/StoreScreen';
import TopActivity from '../Screens/StoreScreen/TopActivity';
import TopActivityActive from '../Screens/StoreScreen/TopActivityActive';
const Stack = createStackNavigator();
const StoreStack = () => {

    return (
        <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >
                 <Stack.Screen 
                
                name="Main">
                    {() => <StoreScreen/>}
                </Stack.Screen>
                 <Stack.Screen component={TopActivity} name="TopActivity" options={{headerShown: false}} />
                <Stack.Screen component={TopActivityActive} name="TopActivityActive" options={{headerShown: false}} />
            </Stack.Navigator>
        </View>
    )
}

export default StoreStack;