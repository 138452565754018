import { View, Text, Pressable, StyleSheet, TouchableOpacity, Image, Dimensions, FlatList, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, Feather, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onCatsAdded, onCatsDeleted, onCatsUpdated } from '../../graphql/subscriptions';

const {width, height} = Dimensions.get('window')

const CategoryScreen = () => {

  const route = useRoute().params;
  const mainColour = '#32a852';

  const navigation = useNavigation();

  const [addingCat, setAddingCat] = useState(false);

  const [titleNew, setTitleNew] = useState();
  const [parent, setParent] = useState();
  const [level, setLevel] = useState(1);

  const [selSub, setSelSub] = useState(false);
  const [categoryList, setCategoryList] = useState([])

  const [catUpdt, setCatUpdt] = useState([])

  const addProd = async()=>{
         const inputNew = {
              title: titleNew,
              level: parent?.level + 1 || 1,
              parentID: parent?.id,
              parentName: parent?.title
            }

            try {
              await API.graphql({query: mutations.createCategoryList, variables: {input: inputNew}}).then((e)=> {setAddingCat(false, setLevel(1), setParent(null), setTitleNew(''))})

            } catch (error) {
              console.log(error)
            }
    
    }


    const fetchCategories = async() =>{
      const allTodos = await API.graphql({ query: queries.listCategoryLists});
      const todos = allTodos.data.listCategoryLists.items

      console.log(todos)
        setCategoryList(todos)

    }

    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onCatsUpdated)).subscribe( {next: (daraa) => {
         setCatUpdt(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onCatsDeleted)).subscribe( {next: (daraaa) => {
         setCatUpdt(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onCatsAdded)).subscribe( {next: (daraaaaa) => {
         setCatUpdt(daraaaaa)
     }, }) 

    }




    useEffect(() => {
      fetchCategories();
      setUpSus();

      return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };
    }, [catUpdt])
    

    const renderCatList = ({item})=>{

      const parentName = item?.parentName

      return(
        <TouchableOpacity onPress={()=> {setParent(item), setSelSub(false)}} style={{height: 50, width: 230, marginRight: 25, 
        padding: 10, alignItems: 'center', justifyContent: 'center', 
        borderWidth: 0, borderRadius: 10, backgroundColor: item?.level === 1 ? '#25402a' : 'white', marginTop: 5}}>
          <Text style={{fontSize: 16, color: item?.level === 1 ? 'white' : 'black'}}>{item?.title}</Text>
          <Text style={{fontSize: 10}}>{item?.level !== 1 ? parentName : ``}</Text>
        </TouchableOpacity>
      )
    }

     const renderCatListMain = ({item})=>{
      const parentName = item?.parentName
      return(
        <TouchableOpacity onPress={()=> {}} style={{height: 50, width: 230, marginRight: 25, 
        padding: 10, alignItems: 'center', justifyContent: 'center', 
        borderWidth: 0, borderRadius: 10, backgroundColor: item?.level === 1 ? '#25402a' : 'white', marginTop: 5}}>
          <Text style={{fontSize: 16, color: item?.level === 1 ? 'white' : 'black' }}>{item?.title}</Text>
          <Text style={{fontSize: 10}}>{item?.level !== 1 ? parentName : ``}</Text>
        </TouchableOpacity>
      )
    }


    if(selSub){
    return(
      <View style={{flex: 1, backgroundColor: '#ffffff', zIndex: 2000}}>
        <View style={{marginTop: 15}}>
          <TouchableOpacity style={{marginLeft: 15, flexDirection: 'row', alignItems: 'center'}} onPress={()=> {setSelSub(false)}}>
            <Ionicons name="arrow-back-outline" size={24} color="black" />
            <Text style={{marginLeft: 15, fontWeight: 'bold'}}>Set Parent Category</Text>
          </TouchableOpacity>
        </View>

        <View style={{marginTop: 25, marginLeft: 15, height: height /2}}>
          <FlatList 
            numColumns={5}
            columnWrapperStyle={{marginRight: 15}}
            data={categoryList}
            renderItem={renderCatList}
            style={{height: height / 2}}
          />
        </View>
      </View>
    )
  }

  if(addingCat){
    return(
      <View style={{flex: 1, backgroundColor: '#ffffff'}}>
        <View style={{marginTop: 15}}>
          <TouchableOpacity style={{marginLeft: 15}} onPress={()=> setAddingCat(false)}>
            <Ionicons name="arrow-back-outline" size={24} color="black" />
          </TouchableOpacity>
        </View>

        <View style={{marginTop: 25, alignItems: 'center'}}>

          <View>
              <Text style={{fontSize: 13, fontWeight: '800'}}>Title</Text>
              <TextInput value= {titleNew} onChangeText = {setTitleNew} style={{width: 250, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
          </View>

          <View style={{marginTop: 10, flexDirection: 'row'}}>
            <Text style={{fontSize: 12, fontStyle: 'italic'}}>Is this a sub-category ?</Text>

            <View >
            <TouchableOpacity onPress={()=> {setLevel(2), setSelSub(true)}} style={{ marginLeft: 15, width: level === 1 ? 20 : 0, height: level === 1 ? 20 : 0, borderWidth: level === 1 ? 0.5 : 0}}>
              
            </TouchableOpacity>

            <TouchableOpacity onPress={()=> setLevel(1)} style={{ marginLeft: 15, width: level === 2 ? 20 : 0, height: level === 2 ? 20 : 0, borderWidth: level === 2 ? 0.5 : 0, alignItems: 'center', justifyContent: 'center'}}>
              <Text style={{fontSize: level === 2 ? 14 : 0}}>X</Text>
            </TouchableOpacity>
          </View>
          
          </View>

          

          <View style={{marginTop: 10}}>
              <Text style={{fontSize: level === 2 ? 12 : 0 , fontWeight: '800'}}>Parent</Text>
              
              <TextInput placeholder='Please input Parent' editable={false} value= {parent?.title} style={{width: level === 2 ? 250 : 0, height: level === 2 ? 45 : 0, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: level === 2 ? 1 : 0, paddingLeft: 10}} />   
          </View>

           <TouchableOpacity onPress={()=> addProd()} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', marginTop: 25, justifyContent: 'center', borderRadius: 5, backgroundColor: mainColour}}>
                    <Ionicons name="add-circle-sharp" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Add</Text> 
                </TouchableOpacity>
          
        </View>
      </View>
    )
  }

   

  

  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>
      <View style={{marginTop: 15}}>
         <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
            <Ionicons name="arrow-back-outline" size={24} color="black" />
        </TouchableOpacity>
      </View>

      <View style={{margin: 15, height: '10%', flexDirection: 'row'}}>
        
          <View>
            {/* filter and search */}

          </View>

           <View style={{flexDirection: 'row'}}>
               <TouchableOpacity onPress={()=> setAddingCat(true)} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: mainColour}}>
                    <Ionicons name="add-circle-sharp" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Add Category</Text> 
                </TouchableOpacity>

                <View style={{borderWidth: 1, marginLeft: 30, borderColor: 'gray', borderRadius: 20, width: 300, height: 40, flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{height: 30, width: 30, marginLeft: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 50, backgroundColor: '#d1d1d1'}}>
                                <Feather name="search" size={24} color="black" />
                            </View>

                            <View style={{marginLeft: 10}}>
                                <TextInput placeholder='Search For Category' style={{width: 250, height: '100%'}} />
                            </View>
                        </View>

          </View> 

          
      </View>

      <View style={{marginTop: 25, marginLeft: 15, height: height / 2}}>
                  <FlatList 
                    numColumns={5}
                    columnWrapperStyle={{marginRight: 15, padding: 15}}
                    style={{height: height / 2}}
                    data={categoryList}
                    renderItem={renderCatListMain}
                  />
              </View>
    </View>
  )
}

export default CategoryScreen

const styles = StyleSheet.create({})