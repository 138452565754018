/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      name
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      name
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      name
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      post {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      post {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      post {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      discount
      DaySpecial
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      discount
      DaySpecial
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      discount
      DaySpecial
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const createProductOD = /* GraphQL */ `
  mutation CreateProductOD(
    $input: CreateProductODInput!
    $condition: ModelProductODConditionInput
  ) {
    createProductOD(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      promoCat
      discount
      promoted
      active
      price
      oldPrice
      filterCat
      filterCatB
      isle
      group
      category
      subCategory
      brand
      quantity
      available
      createdAt
      updatedAt
    }
  }
`;
export const updateProductOD = /* GraphQL */ `
  mutation UpdateProductOD(
    $input: UpdateProductODInput!
    $condition: ModelProductODConditionInput
  ) {
    updateProductOD(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      promoCat
      discount
      promoted
      active
      price
      oldPrice
      filterCat
      filterCatB
      isle
      group
      category
      subCategory
      brand
      quantity
      available
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductOD = /* GraphQL */ `
  mutation DeleteProductOD(
    $input: DeleteProductODInput!
    $condition: ModelProductODConditionInput
  ) {
    deleteProductOD(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      promoCat
      discount
      promoted
      active
      price
      oldPrice
      filterCat
      filterCatB
      isle
      group
      category
      subCategory
      brand
      quantity
      available
      createdAt
      updatedAt
    }
  }
`;
export const createCartProduct = /* GraphQL */ `
  mutation CreateCartProduct(
    $input: CreateCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    createCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const updateCartProduct = /* GraphQL */ `
  mutation UpdateCartProduct(
    $input: UpdateCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    updateCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCartProduct = /* GraphQL */ `
  mutation DeleteCartProduct(
    $input: DeleteCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    deleteCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      option
      price
      image
      title
      titilet
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      option
      price
      image
      title
      titilet
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      option
      price
      image
      title
      titilet
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const createMessageList = /* GraphQL */ `
  mutation CreateMessageList(
    $input: CreateMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    createMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageList = /* GraphQL */ `
  mutation UpdateMessageList(
    $input: UpdateMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    updateMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageList = /* GraphQL */ `
  mutation DeleteMessageList(
    $input: DeleteMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    deleteMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationsList = /* GraphQL */ `
  mutation CreateNotificationsList(
    $input: CreateNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    createNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationsList = /* GraphQL */ `
  mutation UpdateNotificationsList(
    $input: UpdateNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    updateNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationsList = /* GraphQL */ `
  mutation DeleteNotificationsList(
    $input: DeleteNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    deleteNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const createDriverList = /* GraphQL */ `
  mutation CreateDriverList(
    $input: CreateDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    createDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const updateDriverList = /* GraphQL */ `
  mutation UpdateDriverList(
    $input: UpdateDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    updateDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const deleteDriverList = /* GraphQL */ `
  mutation DeleteDriverList(
    $input: DeleteDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    deleteDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const createCategoryList = /* GraphQL */ `
  mutation CreateCategoryList(
    $input: CreateCategoryListInput!
    $condition: ModelCategoryListConditionInput
  ) {
    createCategoryList(input: $input, condition: $condition) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const updateCategoryList = /* GraphQL */ `
  mutation UpdateCategoryList(
    $input: UpdateCategoryListInput!
    $condition: ModelCategoryListConditionInput
  ) {
    updateCategoryList(input: $input, condition: $condition) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategoryList = /* GraphQL */ `
  mutation DeleteCategoryList(
    $input: DeleteCategoryListInput!
    $condition: ModelCategoryListConditionInput
  ) {
    deleteCategoryList(input: $input, condition: $condition) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const createBannerList = /* GraphQL */ `
  mutation CreateBannerList(
    $input: CreateBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    createBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateBannerList = /* GraphQL */ `
  mutation UpdateBannerList(
    $input: UpdateBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    updateBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteBannerList = /* GraphQL */ `
  mutation DeleteBannerList(
    $input: DeleteBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    deleteBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const createReturnProduct = /* GraphQL */ `
  mutation CreateReturnProduct(
    $input: CreateReturnProductInput!
    $condition: ModelReturnProductConditionInput
  ) {
    createReturnProduct(input: $input, condition: $condition) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateReturnProduct = /* GraphQL */ `
  mutation UpdateReturnProduct(
    $input: UpdateReturnProductInput!
    $condition: ModelReturnProductConditionInput
  ) {
    updateReturnProduct(input: $input, condition: $condition) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteReturnProduct = /* GraphQL */ `
  mutation DeleteReturnProduct(
    $input: DeleteReturnProductInput!
    $condition: ModelReturnProductConditionInput
  ) {
    deleteReturnProduct(input: $input, condition: $condition) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createOrderReport = /* GraphQL */ `
  mutation CreateOrderReport(
    $input: CreateOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    createOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderReport = /* GraphQL */ `
  mutation UpdateOrderReport(
    $input: UpdateOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    updateOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderReport = /* GraphQL */ `
  mutation DeleteOrderReport(
    $input: DeleteOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    deleteOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const createShopRequest = /* GraphQL */ `
  mutation CreateShopRequest(
    $input: CreateShopRequestInput!
    $condition: ModelShopRequestConditionInput
  ) {
    createShopRequest(input: $input, condition: $condition) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const updateShopRequest = /* GraphQL */ `
  mutation UpdateShopRequest(
    $input: UpdateShopRequestInput!
    $condition: ModelShopRequestConditionInput
  ) {
    updateShopRequest(input: $input, condition: $condition) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const deleteShopRequest = /* GraphQL */ `
  mutation DeleteShopRequest(
    $input: DeleteShopRequestInput!
    $condition: ModelShopRequestConditionInput
  ) {
    deleteShopRequest(input: $input, condition: $condition) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
