import { View, Text, FlatList, TouchableOpacity, Image, TextInput, ImageBackground, Animated, Dimensions } from 'react-native'
import React, {useState, useEffect, useRef} from 'react'
import { Entypo } from '@expo/vector-icons'; 
import { Ionicons, MaterialCommunityIcons,Feather, MaterialIcons } from '@expo/vector-icons'; 
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onUpdateProductOD, onCreateProductOD, onDeleteProductOD } from '../../graphql/subscriptions';
import uuid from 'react-native-uuid'; 
import { useNavigation } from '@react-navigation/native';
import { storage } from '../../../config';

// import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { API, graphqlOperation, Auth } from 'aws-amplify';

import * as ImagePicker from 'expo-image-picker';
// import * as Firebase from 'firebase'

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const {width, height} = Dimensions.get('window')

const uii = uuid.v4();
const StockMain = () => {


    const navigation = useNavigation();

    const [selecCat, setSelecCat] = useState([])

    const [seleectedStock, setSelectedStock] = useState([])

    const [addingProd, setAddingProd] = useState(false)

    const [stockList, setStockList] = useState([])
    const [reqList, setReqList] = useState([]);

    const [image, setImage] = useState(null);

    const [percentage, setPercentage] = useState(0);
    const [clicked, setClicked] = useState(false)
    const [updStock, setUpdStock] = useState([])
    const [mainCatList, setMainCatList] = useState([])
    const [SubCatList, setSubCatList] = useState([])
    

    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [brand, setBrand] = useState()
    const [price, setPrice] = useState()

    const [mainCategory, setMainCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')

     const [titleNew, setTitleNew] = useState()
    const [descriptionNew, setDescriptionNew] = useState()
    const [brandNew, setBrandNew] = useState()
    const [priceNew, setPriceNew] = useState('')

    const [imgUrl, setImgUrl] = useState()

    const [categoryNew, setCategoryNew] = useState()

    const [available, setAvailable] = useState(true)

    const [addCatMain, setAddCatMain] = useState(false)


    const openMainCat = useRef(new Animated.Value(-height)).current;
    const openSubCat = useRef(new Animated.Value(-height)).current;
    const openBackgroundBack = useRef(new Animated.Value(-height)).current;




    const testList = [{
        id: 1,
        dates: 20220926
    },
    {
        id: 2,
        dates: 20210926
    },
    {
        id: 3,
        dates: 20200925
    },
    {
        id: 4,
        dates: 20221026
    },
    {
        id: 5,
        dates: 20220926
    },
]

    const mainColour = '#32a852';


    const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);
    uploadImage(result)

    if (!result.cancelled) {
      setImage(result?.uri);
    }
  };


    const uploadImage = async(pickerResult)=> {


        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function(){
                resolve(xhr.response);
            };
            xhr.onerror = function(){
                reject(new TypeError('Network request failed'));
            };
            xhr.responseType = 'blob';
            xhr.open('GET', pickerResult?.uri, true);
            xhr.send(null)

        });



        const datee = new Date().toISOString()

        const metadata = {
            contentType: 'image/jpeg',
        };

        const storageRef = ref(storage, datee);

        
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        console.log('Uploaded')
        getDownloadURL(snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
            console.log('d')
        })
        }).catch((error) => {
            console.log(error.message)
        })    
       
    }



    async function fetchProducts() {

        const allCategories = await API.graphql({ query: queries.listCategoryLists, variables: 
        {
            filter :   { level: 
                        {eq: 1},
                        }
        } });

        const catess = allCategories.data.listCategoryLists.items
        setMainCatList(catess)


        // sub categories

         const allSubCategories = await API.graphql({ query: queries.listCategoryLists, variables: 
        {
            filter :   { level: 
                        {ne: 1},
                        }
        } });

        const Subcatess = allSubCategories.data.listCategoryLists.items
        setSubCatList(Subcatess)
      


        const allTodos = await API.graphql({ query: queries.listProductODS});

        const todos = allTodos.data.listProductODS.items
        setStockList(todos)

        // adding requests

        // main Categories

        const allReqs = await API.graphql({ query: queries.listProductODS, variables: 
        {
            filter :   { active: 
                        {eq: true},
                        }
        } });

        const reqData = allReqs.data.listProductODS.items
        setReqList(reqData)



     }

     useEffect(() => {
      fetchProducts()

    }, [updStock])



    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onUpdateProductOD)).subscribe( {next: (daraa) => {
         setUpdStock(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onDeleteProductOD)).subscribe( {next: (daraaa) => {
         setUpdStock(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onCreateProductOD)).subscribe( {next: (daraaaaa) => {
         setUpdStock(daraaaaa)
     }, }) 

    }


    useEffect(() =>{
        setUpSus();
       

        return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };

    },[]);

    const date = new Date()
    console.log(date.toJSON())

    stockList.sort(function(a, b) {
        return parseFloat(a.price) - parseFloat(b.price);
    }); 

  const bula =  testList.sort(function(a, b) {
        return parseFloat(a.dates) - parseFloat(b.dates);
    });

    // testList.sort(function({date: a}, {date: b}) {
    //     return a < b ? -1 : a > b ? 1 : 0
    // });

    // const sortByDate = (data) =>{
    //      testList.sort(({date: a}, {date: b}) => a < b ? -1 : a > b ? 1 : 0)
    //      console.log(sortByDate(data))
    // }
    // sortByDate(testList);

    // console.log(testList)

    const renderCat = ({item})=> {
        return(
            <View style={{marginRight: 10}}>
                <TouchableOpacity onPress={()=> setSelecCat(item) } style={{width: 120, height: 40, alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: item?.id == selecCat?.id ? mainColour : 'black' }}>
                    <Text style={{color: 'white', fontSize: 16}}>{item?.title.toUpperCase()}</Text>
                    
                </TouchableOpacity>
            </View>
        )
    }

    const renderCatAdd = ({item})=> {
        return(
            <View style={{marginRight: 10}}>
                <TouchableOpacity onPress={()=> {setCategoryNew(item?.title), setClicked(true)}} style={{width: 120, height: 40, alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: item?.title == categoryNew ? mainColour : 'black' }}>
                    <Text style={{color: 'white', fontSize: 16}}>{item?.title.toUpperCase()}</Text>
                    
                </TouchableOpacity>
            </View>
        )
    }

    const lineSperate = () =>{
        return(
            <View style={{height: 1, width: '100%', backgroundColor: '#d1d1d1'}} />
        )
    }

    const animShowMainCat = ()=>{

        Animated.timing(openBackgroundBack, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
        }).start()


        Animated.timing(openMainCat, {
            toValue: 0,
            duration: 500,
            delay: 1000,
            useNativeDriver: true,
        }).start()
    }

    const animCloseMainCat = ()=>{

        Animated.timing(openMainCat, {
            toValue: -height,
            duration: 1000,
            useNativeDriver: true,
        }).start()    


         Animated.timing(openBackgroundBack, {
            toValue: -height,
            duration: 1000,
            delay: 1000,
            useNativeDriver: true,
        }).start()
    }

    const animCloseSubCat = ()=>{

        Animated.timing(openSubCat, {
            toValue: -height,
            duration: 1000,
            useNativeDriver: true,
        }).start()    


         Animated.timing(openBackgroundBack, {
            toValue: -height,
            duration: 1000,
            delay: 1000,
            useNativeDriver: true,
        }).start()
    }

    const animShowSubCat = ()=>{

        Animated.timing(openBackgroundBack, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
        }).start()


        Animated.timing(openSubCat, {
            toValue: 0,
            duration: 500,
            delay: 1000,
            useNativeDriver: true,
        }).start()
    }


    const addProd = async()=>{
         const inputNew = {
              image: imgUrl,
              title: titleNew,
              brand: brandNew,
              price: priceNew,
              description: descriptionNew,
              category: mainCategory,
              categoryA: mainCategory,
              categoryB: subCategory,
            //   available: true
            }

        await API.graphql({query: mutations.createProductOD, variables: {input: inputNew}}).then((e)=> {setAddingProd(false)})
    
    }


    const renderingMainCat = ({item})=> {
        return(
            <TouchableOpacity onPress={()=> {setMainCategory(item?.title), setClicked(true), animCloseMainCat()}} style={{width: 120, height: 40, borderWidth: 1, marginRight: 5, marginBottom: 5, alignItems: 'center', justifyContent: 'center', borderColor: '#d1d1d1', borderRadius: 10}}>
                <Text style={{fontSize: 11}}>{item?.title}</Text>
            </TouchableOpacity>

        )
    }

    const renderingSubCat = ({item})=> {
        return(
            <TouchableOpacity onPress={()=> {setSubCategory(item?.title), animCloseSubCat()}} style={{width: 120, height: 40, borderWidth: 1, marginRight: 5, marginBottom: 5, alignItems: 'center', justifyContent: 'center', borderColor: '#d1d1d1', borderRadius: 10}}>
                <Text style={{fontSize: 11}}>{item?.title}</Text>
            </TouchableOpacity>

        )
    }


    if(addingProd === true){

        const showingAddButton = () => {
            if(clicked === true){
                return(
                    <TouchableOpacity onPress={()=> addProd() } style={{height: 40, marginTop: 15, width: 80, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: mainColour}}>
                            <Text style={{color: 'white', fontWeight: '800'}}>Add</Text>
                        </TouchableOpacity>
                )
            } else {
                return(
                    <View  style={{height: 40, marginTop: 15, width: 80, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'gray'}}>
                            <Text style={{color: 'white', fontWeight: '800'}}>Add</Text>
                        </View>
                )
            }
        }


        return(
            <View style={{flex: 1, backgroundColor: '#ffffff'}}>


            <Animated.View style={{height: height / 2, width: width, position: 'absolute', backgroundColor: '#fcfcfc', borderBottomWidth: 1, top: 0, left: 0, zIndex: 3000, transform: [{
                translateY: openMainCat
            }]}}>
                <View style={{marginLeft: 15, marginTop: 15, backgroundColor: '#fcfcfc'}}>
                    <TouchableOpacity onPress={()=> animCloseMainCat()}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>X</Text>
                    </TouchableOpacity>

                    <View style={{margin: 20}}>
                        <FlatList 
                            numColumns={10}
                            data={mainCatList}
                            renderItem={renderingMainCat}
                        />
                    </View>





                </View>
            </Animated.View>

            <Animated.View style={{height: height / 3, width: width, position: 'absolute', backgroundColor: '#fcfcfc', borderBottomWidth: 1, top: 0, left: 0, zIndex: 3000, transform: [{
                translateY: openSubCat
            }]}}>
                <View style={{marginLeft: 15, marginTop: 15, backgroundColor: '#fcfcfc'}}>
                    <TouchableOpacity onPress={()=> animCloseSubCat()}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>X</Text>
                    </TouchableOpacity>

                    <View style={{margin: 20}}>
                        <FlatList 
                            numColumns={10}
                            data={SubCatList}
                            renderItem={renderingSubCat}
                        />
                    </View>





                </View>
            </Animated.View>

            <Animated.View style={{height:'100%', width: '100%', backgroundColor: 'rgba(0,0,0, 0.8)', position: 'absolute', zIndex: 2500, transform: [{
                translateY: openBackgroundBack
            }]}}>
                <TouchableOpacity>

                </TouchableOpacity>
            </Animated.View>




                <View style={{marginTop: 15}}>
                    <TouchableOpacity style={{marginLeft: 15}} onPress={()=> setAddingProd(false)}>
                        <Ionicons name="arrow-back-outline" size={24} color="black" />
                    </TouchableOpacity>


                    <View style={{marginTop: 25, flexDirection: 'row', alignSelf: 'center'}}>

                        <TouchableOpacity onPress={()=> pickImage()} style={{paddingLeft: 0}}>
                        <ImageBackground style={{width: 300, height: 300, borderRadius: 10, alignItems: 'center',justifyContent: 'center', borderWidth: 1, borderColor: '#d1d1d1'}} imageStyle={{borderRadius: 10}}  source={{uri: image}}>
                            <View style={{width: 30, height: 30, borderRadius: 50, backgroundColor: 'black', alignItems: 'center', justifyContent: 'center'}}>
                                <Entypo name="camera" size={15} color= 'white' />
                            </View>
                        </ImageBackground>
                        </TouchableOpacity>

                        <View style={{marginLeft: 40}}>
                        <View style={{flexDirection: 'row'}}>

                        
                        <View>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Title</Text>
                            <TextInput value= {titleNew} onChangeText = {setTitleNew} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                         <View style={{marginTop: 0, marginLeft: 20}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Description</Text>
                            <TextInput multiline={true} value= {descriptionNew} onChangeText={setDescriptionNew} style={{width: 150, height: 80, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                        </View>

                       <View style={{flexDirection: 'row'}}>

                       <View style={{marginTop: 15}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Brand</Text>
                            <TextInput keyboardAppearance='numeric' value= {brandNew} onChangeText={setBrandNew} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                        <View style={{marginTop: 15, marginLeft: 20}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Price (ZAR) - Number only</Text>
                            <TextInput keyboardAppearance='numeric' value= {priceNew} onChangeText={setPriceNew} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                        </View>

                        {/* category */}
                        
                        
                        {showingAddButton()}
                     
                    </View>

                        
                        <View>

                         <View style={{ marginLeft: 20}}>
                          
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Main Category</Text>

                            <TouchableOpacity onPress={()=> animShowMainCat()}> 
                                <Text style={{fontSize: 11, fontStyle: 'italic'}}>{mainCategory || `Click to set Main Category`}</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={{ marginLeft: 20, marginTop: 15}}>
                          
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Sub-Category</Text>

                            <TouchableOpacity onPress={()=> animShowSubCat()}> 
                                <Text style={{fontSize: 11, fontStyle: 'italic'}}>{subCategory || `Click to set Sub-Category`}</Text>
                            </TouchableOpacity>
                        </View>
                        </View>
                    </View>

                   


                </View>


            </View>
        )
    }


    const renderStock = ({item}) => {

        return(
            <View style={{ borderBottomWidth: 1, borderColor: '#d1d1d1'}}>
                <TouchableOpacity onPress={()=> navigation.navigate('ProductsScreen', {item: item})} style={{padding: 10, flexDirection: 'row'}}>
                    <Image style={{height: 60, width: 60, borderRadius: 5, borderWidth: 3, borderColor: item?.id == seleectedStock?.id ? mainColour : 'white'}} source={{uri: item?.image}}/>
                    <View style={{marginLeft: 20}}>
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item?.title}</Text>
                        <Text style={{ fontSize: 11}}>{item?.shop || `No Shop Name`}</Text>
                    <Text style={{ fontSize: 11,}}>R{Number(item?.price).toFixed(2)}</Text>
                    <Text style={{ fontSize: 11,}}>{item?.quantity || `No`} Items Left</Text>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    const renderReqList = ({item}) => {

        return(
            <View style={{ borderBottomWidth: 1, borderColor: '#d1d1d1'}}>
                <TouchableOpacity onPress={()=> navigation.navigate('ProdReqScreen', {item: item})} style={{padding: 10, flexDirection: 'row'}}>
                    <Image style={{height: 60, width: 60, borderRadius: 5, borderWidth: 3, borderColor: item?.id == seleectedStock?.id ? mainColour : 'white'}} source={{uri: item?.image}}/>
                    <View style={{marginLeft: 20}}>
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item?.title}</Text>
                        <Text style={{ fontSize: 11}}>{item?.shop || `No Shop Name`}</Text>
                    <Text style={{ fontSize: 11,}}>R{Number(item?.price).toFixed(2)}</Text>
                    <Text style={{ fontSize: 11,}}>{item?.category}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }


    const showingAvail = ()=> {

        if(seleectedStock?.available === true){
             return(
                    <View>
                        <TouchableOpacity onPress={()=> updateUnAvail()} style={{height: 40, width: 170, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'red'}}>
                                <Text style={{color: 'white', fontWeight: '800'}}>Set to Unavailable</Text>
                            </TouchableOpacity>
                    </View>
                )
   
        } else if(seleectedStock?.available === false){
            return(
                <View>
                        <TouchableOpacity onPress={()=> updateAvail()} style={{height: 40, width: 170, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'green'}}>
                                <Text style={{color: 'white', fontWeight: '800'}}>Set to Available</Text>
                            </TouchableOpacity>
                    </View>
            )
        }
        
    }


    const updateProd= async() =>{
         const inputUpdate = {
              id: seleectedStock?.id,
            //   image: 'https://images.unsplash.com/photo-1560015534-cee980ba7e13?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80',
              title: title,
              brand: brand,
              price: price,
              description: description,
            }
             await API.graphql({query: mutations.updateProductOD, variables: {input: inputUpdate}}).then((e)=> {})

    } 

    const updateAvail = async()=> {
        
         const inputUpdate = {
              id: seleectedStock?.id,
              available: true
         }

        await API.graphql({query: mutations.updateProductOD, variables: {input: inputUpdate}}).then((e)=> fetchProducts())

    }

    const updateUnAvail = async()=> {
        
         const inputUpdate = {
              id: seleectedStock?.id,
              available: false
         }

        await API.graphql({query: mutations.updateProductOD, variables: {input: inputUpdate}}).then((e)=> {})

    }

    const deleteProd = async()=> {
        
         const inputUpdate = {
              id: seleectedStock?.id,
         }

        await API.graphql({query: mutations.deleteProductOD, variables: {input: inputUpdate}}).then((e)=> {})

    }

  return (
    <View style={{backgroundColor: 'white', flex: 1}}>

    



      
      <View style={{margin: 15, height: '10%', flexDirection: 'row'}}>
        
          <View>
            {/* filter and search */}

          </View>

           <View style={{flexDirection: 'row'}}>
               <TouchableOpacity onPress={()=> setAddingProd(true)} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: mainColour}}>
                    <Ionicons name="add-circle-sharp" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Add Product</Text> 
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> navigation.navigate('CategoryScreen')} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: 'red', marginLeft: 15}}>
                    <MaterialIcons  name="category" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Categories</Text> 
                </TouchableOpacity>
          </View> 
      </View>

      <View style={{width: '100%', height: '90%', flexDirection: 'row'}}>
          

        <View style={{marginTop: 0, marginLeft: 15, width: '100%', height: '100%', borderRightWidth: 0, borderColor: '#d1d1d1'}}>
            {/* search input */}
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View style={{borderWidth: 1, borderColor: 'gray', borderRadius: 20, width: 300, height: 40, flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{height: 30, width: 30, marginLeft: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 50, backgroundColor: '#d1d1d1'}}>
                        <Feather name="search" size={24} color="black" />
                    </View>

                    <View style={{marginLeft: 10}}>
                        <TextInput placeholder='Mercer M544 Laptop' style={{width: 250}} />
                    </View>
                </View>

                <View style={{marginLeft: 15}}>
                    <Text style={{fontSize: 18, fontWeight: '700'}}>All Products</Text>
                </View>
            </View>
            <FlatList 
                data={stockList}
                keyExtractor={(item, index) => index.toString()}
                renderItem={renderStock}
            />
        </View>

        

       
      </View>


    </View>
  )
}

export default StockMain