import { View, Text } from 'react-native'
import React from 'react'
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Entypo, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, FontAwesome   } from '@expo/vector-icons';
import UsersStack from './UsersStack.tsx';
import OrdersStack from './OrdersStack';
import StockStack from './StockStack';
import StoreStack from './Store';
// import StockStack from './StockStack';
// import OrdersStack from './OrdersStack';
// import HomeScreen from '../Screens/HomeScreen/HomeScreen';
import DriversStack from './DriversStack';
// import SecHome from '../Screens/HomeScreen/SecHome';
import HomeStack from './HomeStack';

const Tab = createMaterialTopTabNavigator();
const TopNa = () => {


    const emptyPage = ()=>{
        return(
            <View>

            </View>
        )
    }

  return (
      <NavigationContainer>
      <Tab.Navigator  screenOptions={{
            tabBarLabelStyle: { fontSize: 10 },
            tabBarActiveTintColor: '#32a852',
            tabBarInactiveTintColor: '#100f26'
            }}>

             <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <FontAwesome  name="home" color={color} size={20}/>
                    
                }} name="Home" component={HomeStack} />
                

            <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <FontAwesome  name="user" color={color} size={20}/>
                    
                }} name="Users" component={UsersStack} />

                <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <FontAwesome  name="car" color={color} size={20}/>
                    
                }} name="Drivers" component={DriversStack} />

           

                <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <FontAwesome  name="list-alt" color={color} size={20}/>
                    
                }} name="Stock" component={StockStack} />


                <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <Octicons  name="bookmark" color={color} size={20}/>
                    
                }} name="Orders" component={OrdersStack} />

                {/* <Tab.Screen  options={{
                    tabBarIcon: ({color, size}) => 
                    <MaterialIcons name="chat" color={color} size={20}/>
                    
                }} name="Chat" component={emptyPage} /> */}
            </Tab.Navigator>
        <View>
        </View>
    </NavigationContainer>
  )
}

export default TopNa