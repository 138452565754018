import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, Animated, FlatList, TextInput, StyleSheet } from 'react-native'
import React, {useState, useEffect, useRef} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { getProducts } from '../../graphql/queries';
import { getProductOD } from '../../graphql/queries';
import { onUpdateProductOD } from '../../graphql/subscriptions';
const {width, height} = Dimensions.get('window');

const ProductsScreen = () => {

    const route = useRoute().params
    const navigation = useNavigation();

    const mainColour = '#32a852';

    const hei = useRef(new Animated.Value(-height)).current;
    const promoMain = useRef(new Animated.Value(height)).current;


    const scheduleList = [
        {
        id: 1,
        title: 'Clearance'
        },
        {
        id: 2,
        title: 'Men'
        },
        {
        id: 3,
        title: 'Women'
        },
        {
        id: 4,
        title: 'Baby'
        },
        {
        id: 5,
        title: 'Tech'
        },
        {
        id: 6,
        title: 'Bedroom'
        },
        {
        id: 6,
        title: 'Loadshedding'
        },
]




    const animDiscount = ()=>{
        Animated.timing(hei, {
            toValue: 0,
            useNativeDriver: true,
    }).start()
    }

    const closeDiscount = ()=>{
        Animated.timing(hei, {
            toValue: -height,
            useNativeDriver: true,
    }).start()
    }

    const animPromo = ()=>{
        Animated.timing(promoMain, {
            toValue: 0,
            useNativeDriver: true,
    }).start()
    }

    const closePromo = ()=>{
        Animated.timing(promoMain, {
            toValue: height,
            useNativeDriver: true,
    }).start()
    }



    const discountPerc = ((parseFloat(prodDet?.price) - parseFloat(prodDet?.newPrice)) / ( parseFloat(prodDet?.price)))*100


    const [newPricing, setNewPricing] = useState();
    const [prodDet, setProdDet] = useState([]);
    const [updStock, setUpdStock] = useState([])

    const [schedule, setSchedule] = useState();

    async function getProductDetails(){
        const gettingUser = await API.graphql(graphqlOperation(getProductOD, { id: route?.item?.id}))
    const gettingUserData = gettingUser.data.getProductOD

        console.table(gettingUserData)
        setProdDet(gettingUserData)
    }


    let subsUpdate;


     function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onUpdateProductOD)).subscribe( {next: (daraa) => {
         setUpdStock(daraa)
     }, })
    
    }

    useEffect(() => {
      getProductDetails();

      setUpSus();
       

        return() =>{
            subsUpdate.unsubscribe();

        };

    }, [updStock])
    

    async function updateNewPrice(){

        const userInput = {
              id: route?.item?.id,
              newPrice: newPricing,
            }

            try {
              await API.graphql({query: mutations.updateProductOD, variables: {input: userInput}}).then((e)=> {
            //   setSpin(true),
              closeDiscount()
              })
            } catch (error) {
              console.warn('error')
            }


    }

    async function updateAvail(){

        const userInput = {
              id: route?.item?.id,
              active: !prodDet?.active,
            }

            try {
              await API.graphql({query: mutations.updateProductOD, variables: {input: userInput}}).then((e)=> {
            //   setSpin(true),
            //   closeDiscount()
            console.warn('done')
              })
            } catch (error) {
              console.log('error')
            }


    }

    async function updateSchedule(){

        const userInput = {
              id: route?.item?.id,
              promoCat: schedule,
            }

            try {
              await API.graphql({query: mutations.updateProductOD, variables: {input: userInput}}).then((e)=> {
            //   setSpin(true),
              closePromo(),
            console.warn('done')
              })
            } catch (error) {
              console.log('error')
            }


    }

    async function updateTogglePromo(){

        const userInput = {
              id: route?.item?.id,
              promoted: !prodDet?.promoted,
            }

            try {
              await API.graphql({query: mutations.updateProductOD, variables: {input: userInput}}).then((e)=> {
            //   setSpin(true),
            //   closeDiscount()
            console.warn('done')
              })
            } catch (error) {
              console.log('error')
            }


    }


    const renderScheduleList =({item})=>{
        return(
            <TouchableOpacity onPress={()=> setSchedule(item?.title)} style={{height: 35, borderWidth: schedule === item?.title ? 1 : 0, borderColor: 'red', marginRight: 15, width: 75, borderRadius: 10, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color: 'white'}}>{item?.title}</Text>
            </TouchableOpacity>
        )
    }


  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>
      <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>
      <View style={{marginTop: 15, flexDirection: 'row'}}>
        <View style={{width: '33%', paddingLeft: 15, paddingTop: 30, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
         
                <View>
                    <Image style={{height: 250, width: 250}} source={{uri: route?.item?.image}}/>
                </View>

                
        </View>
        <View style={{width: '33%', borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1'}}>
                <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>Details</Text>
                </View> 
                
                <View style={{margin: 15}}>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Store
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.shop || `--`}
                    </Text>
                </View> 

                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Store ID
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.shopID || `--`}
                    </Text>
                </View> 

                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Title
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.title}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Brand
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.brand || `--`}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Category
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.category}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Isle
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.isle}
                    </Text>
                </View>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Group
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.group}
                    </Text>
                </View>  
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Price
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        R{Number(prodDet?.price).toFixed(2) || `0.00`}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Current Price
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        R{Number(prodDet?.newPrice).toFixed(2) || `0.00`}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Quantity
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{ fontSize: 11}}>
                        {route?.item?.quantity}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Sizes
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.sizes}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Gender
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.gender || `Neutral`}
                    </Text>
                </View>

                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 11, width: '20%'}}>
                        Description
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontSize: 11}}>
                        {route?.item?.description || `--`}
                    </Text>
                </View>  




                </View>



        </View>
        <View style={{width: '34%', borderRightWidth: 0, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1'}}>
                <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>Options</Text>
                </View>  

                <TouchableOpacity onPress={()=> {animDiscount()}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo  name="check" size={20} color="white" />
                </View>
                
                <Text>Set Discount</Text>
                </TouchableOpacity>  

                <TouchableOpacity onPress={()=> {updateAvail()}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>{prodDet?.active === true ? `Set To Inactive` : `Set To Active` }</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>Re-Order</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> {animPromo()}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>Promote</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>Update Product</Text>
                </TouchableOpacity>

        </View>
      </View>

      <Animated.View style={{position: 'absolute', width: width,  height: '100%', backgroundColor: '#fff', transform: [{
        translateY: hei
      }]}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity  style={{marginLeft: 15}} onPress={()=> closeDiscount()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>
        <View style={{margin: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Set Discount</Text>
        </View>

        <View style={{width: width, alignItems: 'center', justifyContent: 'center'}}>
            <View style={{flexDirection: 'row'}}>
                <Text style={{width: 200}}>Item Price</Text>
                 <Text>R{Number(prodDet?.price).toFixed(2)}</Text>
            </View>

            <View style={{flexDirection: 'row', marginTop: 15}}>
                <Text style={{width: 200}}>Current Price</Text>
                 <Text>R{Number(prodDet?.newPrice).toFixed(2)}</Text>
            </View>

            {/* <View style={{flexDirection: 'row', marginTop: 15}}>
                <Text style={{width: 200}}>Discount Percentage</Text>
                 <Text>{Number(discountPerc).toFixed(0)}%</Text>
            </View> */}

            <View style={{height: 1, width: '100%', marginTop: 25, backgroundColor: 'gray', opacity: 0.5}}/>
            
            <View style={{flexDirection: 'row', marginTop: 15}}>
                <Text style={{width: 200}}>Enter New Price</Text>
                 <TextInput onChangeText={setNewPricing} value={newPricing} style={{height: 45, width: 200, borderWidth: 1, borderRadius: 10, paddingLeft: 15}} placeholder='Enter Price'/>
            </View>

            <View style={{marginTop: 30}}>
                <TouchableOpacity onPress={()=> updateNewPrice()} style={{height: 45, width: 200, borderRadius: 20, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color: 'white', fontSize: 16}}>Update</Text>
                </TouchableOpacity>
            </View>

        </View>
      </Animated.View>

      <Animated.View style={{position: 'absolute', width: width,  height: '100%', backgroundColor: '#fff', transform: [{
        translateY: promoMain
      }]}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity  style={{marginLeft: 15}} onPress={()=> closePromo()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>
        <View style={{margin: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Set Pomotion</Text>
        </View>

        <View style={{width: width, alignItems: 'center', justifyContent: 'center'}}>
            
            
            <View style={{flexDirection: 'row', marginTop: 15}}>
                <Text style={{width: 200}}>Click on the schedule name</Text>
            </View>

            <View style={{marginTop: 15}}>
                <FlatList 
                    horizontal
                    data={scheduleList}
                    renderItem={renderScheduleList}
                />
            </View>


            <View style={{marginTop: 30, flexDirection: 'row'}}>
                <TouchableOpacity onPress={()=> updateSchedule()} style={{height: 45, width: 200, borderRadius: 20, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color: 'white', fontSize: 16}}>Update</Text>
                </TouchableOpacity>

                 <TouchableOpacity onPress={()=> updateTogglePromo()} style={{height: 45, marginLeft: 15, width: 200, borderRadius: 20, borderWidth: 1, borderColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color:  mainColour, fontSize: 16}}>{prodDet?.promoted === true ? `Remove Promo` : `Activate Promo` || `...`}</Text>
                </TouchableOpacity>
            </View>

        </View>
      </Animated.View>
    </View>
  )
}

export default ProductsScreen

const styles = StyleSheet.create({})