import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onUpdateDriverList, onUpdatedDriver, onDeletedDriver } from '../../graphql/subscriptions';

const EditDriver = () => {

    const navigation = useNavigation();
    const route = useRoute().params
    const mainColour = '#FF4F00'

    const [name, setName] = useState();
    const [surname, setSurname] = useState();
    const [car, setCar] = useState();
    const [model, setModel] = useState();
    const [plate, setPlate] = useState();



    const updateUnAvail = async()=> {
        
         const inputUpdate = {
              id: route?.id,
              name: name,
              surname: surname,
              plate: plate,
              model: model,
              car: car
         }

         try{
            await API.graphql({query: mutations.updateDriverList, variables: {input: inputUpdate}}).then((e)=> {navigation.goBack()})
         } catch(e){
            console.log(e)
         }
    }


    

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>


        </View>

        <View style={{marginTop: 15, borderTopWidth: 1, borderColor: '#d1d1d1', width: '100%'}}>
            <View style={{margin: 15}}>
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Edit Driver</Text>

            </View>
        </View>

        <View style={{flexDirection:'row'}}>

            {/* image */}
            <View style={{margin: 15}}>
                <TouchableOpacity >
                    <Image style={{width: 200, height: 200, borderRadius: 100}} source={require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/>
                </TouchableOpacity>
            </View>

            <View style={{marginLeft: 200, flexDirection: 'row'}}>

                <View>

            
                {/* first name */}
                    <View>
                        <Text style={{marginBottom: 5}}>First Name</Text>
                        <TextInput value={name} onChangeText={setName} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder= {route?.name} />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Surname</Text>
                        <TextInput value={surname} onChangeText={setSurname} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder={route?.surname} />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Car</Text>
                        <TextInput value={car} onChangeText={setCar} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder={route.car || `Car`} />
                    </View>

                </View>

                <View style={{marginLeft: 30}}>
                    <View style={{marginTop: 0}}>
                        <Text style={{marginBottom: 5}}>Model</Text>
                        <TextInput value={model} onChangeText={setModel} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder= {route?.model || `Model`} />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Plate</Text>
                        <TextInput value={plate} onChangeText={setPlate} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder= {route?.plate || `Plate`} />
                    </View>

                    <TouchableOpacity onPress={()=>  {updateUnAvail()}} style={{width: 200, marginTop: 35, borderRadius: 5, height: 45, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{color: 'white'}}>Update Details</Text>
                    </TouchableOpacity>

                </View>
            </View>
        </View>
    </View>
  )
}

export default EditDriver