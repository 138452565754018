import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import Empty from '../Empty';
import UsersScreen from '../Screens/UsersScreen';
import UserDetails from '../Screens/UsersScreen/UserDetails';
import TopActivity from '../Screens/UsersScreen/TopActivity';
const Stack = createStackNavigator();

const UsersStack = () => {

    return (
        <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >

                <Stack.Screen 
                
                name="Users">
                    {() => <UsersScreen/>}
                </Stack.Screen>
                 <Stack.Screen component={UserDetails} name="UserDetails" options={{headerShown: false}} />
               <Stack.Screen component={TopActivity} name="TopActivity" options={{headerShown: false}} />

            </Stack.Navigator>
        </View>
    );

};

export default UsersStack;