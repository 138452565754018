import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import UsersScreen from '../Screens/UsersScreen';
import UserDetails from '../Screens/UsersScreen/UserDetails';
import TopActivity from '../Screens/UsersScreen/TopActivity';
import StockMain from '../Screens/StockScreen/StockMain';
import TopActivityCompleted from '../Screens/OrdersScreen/TopActivityCompleted';
import CategoryScreen from '../Screens/StockScreen/CategoryScreen';
import ProdReqScreen from '../Screens/StockScreen/ProdReqScreen';
import ProductsScreen from '../Screens/StockScreen/ProductsScreen';
// import EmergencyMain from '../Screens/StockScreen.js/EmergencyMain';

const Stack = createStackNavigator();

const StockStack = () => {

    return (
        <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >

                <Stack.Screen 
                
                name="Main">
                    {() => <StockMain/>}
                </Stack.Screen>
                 <Stack.Screen component={CategoryScreen} name="CategoryScreen" options={{headerShown: false}} />
                 <Stack.Screen component={ProdReqScreen} name="ProdReqScreen" options={{headerShown: false}} />
                 <Stack.Screen component={ProductsScreen} name="ProductsScreen" options={{headerShown: false}} />

            </Stack.Navigator>
        </View>
    );

};

export default StockStack;