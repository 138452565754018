import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onAddedOrder, onUpdatedOrder, onDeletedOrder } from '../../graphql/subscriptions';
import { storage } from '../../../config';
import * as ImagePicker from 'expo-image-picker';

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const AddDriver = () => {

    const navigation = useNavigation();
    const route = useRoute().params
    const mainColour = '#FF4F00'

     const [UserList, setUserList] = useState([])
     const [name, setName] = useState([])
     const [surname, setSurname] = useState([])
     const [car, setCar] = useState([])
     const [model, setModel] = useState([])
     const [plate, setPlate] = useState([])

    const [imgUrl, setImgUrl] = useState()
    const [image, setImage] = useState(null);


    async function fetchUsers() {

        const allTodos = await API.graphql({ query: queries.listUserData});

        const todos = allTodos.data.listUserData.items
        setUserList(todos)


        console.log('items :', todos)

     }


      useEffect(() => {
      fetchUsers()

    }, [])

     const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    // handleUpload(result)

    uploadImage(result)

    // setImageUri(result?.uri)
      
    if (!result.cancelled) {
      setImage(result?.uri);
    }
  };


  const uploadImage = async(pickerResult)=> {


        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function(){
                resolve(xhr.response);
            };
            xhr.onerror = function(){
                reject(new TypeError('Network request failed'));
            };
            xhr.responseType = 'blob';
            xhr.open('GET', pickerResult?.uri, true);
            xhr.send(null)

        });



        const datee = new Date().toISOString()

        const metadata = {
            contentType: 'image/jpeg',
        };

        const storageRef = ref(storage, datee);

        
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        console.log('Uploaded')
        getDownloadURL(snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
            console.log('d')
            // console.log('File available at', downloadURL);
        })
        }).catch((error) => {
            console.log(error.message)
        })    
       
    }

     const addDriver = async()=>{
         const inputNew = {
              id: route?.id,
              name: name,
              surname: surname,
              active: false,
              userID: route?.id,
              car: car,
              plate: plate,
              model: model,
              image: imgUrl,
            }

            const inputUpdateUser = {
              id: route?.id,
              driver: true,
            }

        await API.graphql({query: mutations.createDriverList, variables: {input: inputNew}}).then((e)=> {
            API.graphql({query: mutations.updateUserData, variables: {input: inputUpdateUser}}).then((b)=> {
                navigation.goBack()
            })
           
        })
    
    }

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>


        </View>

        <View style={{marginTop: 15, borderTopWidth: 1, borderColor: '#d1d1d1', width: '100%'}}>
            <View style={{margin: 15}}>
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Add Driver - {route?.name}</Text>

            </View>
        </View>

        <View style={{flexDirection:'row'}}>

            {/* image */}
            <View style={{margin: 15}}>
                <TouchableOpacity onPress={()=> pickImage()} >
                    {/* <Image style={{width: 200, height: 200, borderRadius: 100}} source=   {require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/> */}
                
                
                     <Image style={{width: 200, height: 200, borderRadius: 100}} source=  {image || require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/>
 
                
                </TouchableOpacity>
            </View>

            <View style={{marginLeft: 200, flexDirection: 'row'}}>

                <View>

            
                {/* first name */}
                    <View>
                        <Text style={{marginBottom: 5}}>First Name</Text>
                        <TextInput value={name} onChangeText={setName} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder={route?.name} />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Surname</Text>
                        <TextInput value={surname} onChangeText={setSurname}  style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder={route?.surname} />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Car</Text>
                        <TextInput  value={car} onChangeText={setCar}  style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder='Car' />
                    </View>

                </View>

                <View style={{marginLeft: 30}}>
                    <View style={{marginTop: 0}}>
                        <Text style={{marginBottom: 5}}>Model</Text>
                        <TextInput value={model} onChangeText={setModel} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder='Model' />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Plate</Text>
                        <TextInput value={plate} onChangeText={setPlate} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder='Plate' />
                    </View>

                    <TouchableOpacity onPress={()=> addDriver()} style={{width: 200, marginTop: 35, borderRadius: 5, height: 45, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{color: 'white'}}>Update Details</Text>
                    </TouchableOpacity>

                </View>
            </View>
        </View>
    </View>
  )
}

export default AddDriver