import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput, } from 'react-native'
import React, {useState, useEffect} from 'react'
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onOrderAdded, onOrderUpdated } from '../../graphql/subscriptions';


const {width, height} = Dimensions.get('window')
const TopActivityCompleted = () => {
 
    const navigation = useNavigation();
    const route = useRoute().params

    const {orderDet} = route

    const mainColour = '#FF4F00';

    const [driverSelect, setDriverSelect] = useState([])
    const [orderInfo, setOrderInfo] = useState([])
    const [orderProd, setOrderProd] = useState([])
    const [orderUpdt ,setOrderUpdt] = useState([])


    const OrderProduct = [
        {   id: 1,
            quantity: 2,
            price: 33,
            image: 'https://images.unsplash.com/photo-1611159964154-831c28aa83bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            title: 'Petrol 93',
            brand: 'FIrst Petroleum',
            cylinder: null,
            productID: 1,
            orderID: 1,
        },
        {   id: 2,
            quantity: 2,
            price: 40,
            image: 'https://images.unsplash.com/photo-1611159964154-831c28aa83bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            title: 'Diesel 550ppm',
            brand: 'FIrst Petroleum',
            cylinder: null,
            productID: 1,
            orderID: 1
        },
        {   id: 3,
            quantity: 1,
            price: 20,
            image: 'https://images.unsplash.com/photo-1611159964154-831c28aa83bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            title: 'Petrol 95',
            brand: 'FIrst Petroleum',
            cylinder: null,
            productID: 1,
            orderID: 3
        },
        {   id: 4,
            quantity: 1,
            price: 200,
            image: 'https://images.unsplash.com/photo-1611159964154-831c28aa83bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            title: 'Ad Blue',
            brand: 'FIrst Petroleum',
            cylinder: null,
            productID: 1,
            orderID: 4
        },
        {   id: 5,
            quantity: 1,
            price: 200,
            image: 'https://images.unsplash.com/photo-1611159964154-831c28aa83bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            title: 'Ad Blue',
            brand: 'FIrst Petroleum',
            cylinder: null,
            productID: 1,
            orderID: 4
        }
        ,
        {   id: 6,
            quantity: 1,
            price: 200,
            image: 'https://images.unsplash.com/photo-1611159964154-831c28aa83bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            title: 'Ad Blue',
            brand: 'FIrst Petroleum',
            cylinder: null,
            productID: 1,
            orderID: 4
        }
    ]


    const driver = 
        {id: 1,
        name: 'Senzo',
        surname: 'Dlamini',
        carPlate: 'JM887MO',
        car: 'Nissan',
        model: 'N345',
        imageUrl: 'https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
    }

    async function fetchUsers() {

        const inputGet = {
            id: orderDet?.id
        }

        const allOrders = await API.graphql({ query: queries.getOrder, variables: inputGet });

        const todos = allOrders.data.getOrder
        setOrderInfo(todos)
        console.log('shaye', todos)


         const allOrdersList = await API.graphql({ query: queries.listOrderProducts, variables: {
             filter: {
             orderID: {eq: orderDet?.id}
             }
         }});

         const todoss = allOrdersList.data.listOrderProducts.items

         setOrderProd(todoss)



     }

     useEffect(() => {
        fetchUsers();
     }, [orderUpdt]) 
    

    const renderList = ({item})=>{
        return(
            <View style={{marginRight: 10}}>
                <View>
                    <Image style={{width: 80, height: 80, borderRadius: 5}} source={{uri: item?.image}} />
                </View>
                <View>
                    <Text style={{ fontSize: 12}}>{item?.title}</Text>
                    <Text style={{ fontSize: 12}}>Qty : {item?.quantity}</Text>
                    <Text style={{ fontSize: 12}}>R{Number(item?.price).toFixed(2)}</Text>
                </View>
            </View>
        )
    }

    const renderDrivers = ({item}) => {
        return(
            <View style={{marginRight: 15}}>
                <TouchableOpacity onPress={()=> setDriverSelect(item)} style={{ width: '100%'}}>
                    <Image style={{height: 80, width: 80, borderRadius: 10, borderWidth: 3, borderColor: item?.id == driverSelect?.id ? mainColour : 'white'}} source={{uri: item?.imageUrl}} />
                    <Text style={{fontSize: 10}}>{item?.name} {item?.surname}</Text>
                </TouchableOpacity>

            </View>
        )
    }

    const cancelOrder = async()=> {
        const cancelInput ={
            id: orderDet?.id,
            deliveryStatus: 'Cancelled',
            active: false
        }

        try{

            await API.graphql({ query: mutations.updateOrder, variables: {input: cancelInput}});

        }catch(e){
            console.log(e)
        }

    }

    const showCancelOption = ()=>{
        if(orderInfo?.active === true){
            return(
                <View style={{width: '100%'}}>
                     <TouchableOpacity onPress={()=> cancelOrder()} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: 'red', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="cross" size={20} color="white" />
                </View>
                
                <Text>Cancel Order</Text>
                    </TouchableOpacity>
                </View>
            )
        }
    }


    const showingContent = () => {
        if(orderDet?.steps === null){
            return(
                <View>
                    <Text style={{fontSize: 12, }}>Accepted</Text>
                </View>
            )    
        } else if(orderDet?.steps === 2){
             return(
                <View>
                  <Text style={{fontSize: 12, }}>Driver is fetching order</Text>
                </View>
             )   
        } else if(orderDet?.steps === 3){
            return(
                <View>
                    <Text style={{fontSize: 12, }}>Driver is on the way</Text>
                </View>
            )
        } else if(orderDet?.steps === 4){
            return(
                <View>
                    <Text style={{fontSize: 12, }}>Driver has arrived</Text>
                </View>
            )
        }
    }

    
    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onOrderUpdated)).subscribe( {next: (daraa) => {
         setOrderUpdt(daraa)
     }, })
     
  
     subsUpdateCreate = API.graphql(graphqlOperation(onOrderAdded)).subscribe( {next: (daraaaaa) => {
         setOrderUpdt(daraaaaa)
     }, }) 

    }

    useEffect(() =>{
        setUpSus();

        return() =>{
            subsUpdate.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };

    },[]);

  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>

            <View style={{marginTop: 15, flexDirection: 'row'}}>

                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Order Details</Text>
                    </View>

                    <View style={{width: '100%', padding: 15}}>

                            <View style={{flexDirection: 'row'}}>
                                <Text style={{width: 150, fontSize: 12}}>Type</Text>
                                <Text style={{width: 40, fontSize: 12}}>:</Text>
                                <Text style={{fontWeight: 'bold', fontSize: 12}}>{orderDet?.type}</Text>
                            </View>

                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <Text style={{width: 150, fontSize: 12}}>Status</Text>
                                <Text style={{width: 40, fontSize: 12}}>:</Text>
                                <Text style={{fontWeight: 'bold', color: mainColour, fontSize: 12}}>{orderInfo?.deliveryStatus}</Text>
                            </View>

                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <Text style={{width: 150, fontSize: 12}}>Plus</Text>
                                <Text style={{width: 40, fontSize: 12}}>:</Text>
                                {showingContent()}
                            </View>


                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <Text style={{width: 150, fontSize: 12}}>Order Number</Text>
                                <Text style={{width: 40, fontSize: 12}}>:</Text>
                                <Text style={{fontSize: 12}}>{orderDet?.id.slice(0,10)}...</Text>
                            </View>

                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <Text style={{width: 150, fontSize: 12}}>Date</Text>
                                <Text style={{width: 40, fontSize: 12}}>:</Text>
                                <Text style={{fontSize: 12}}>{orderDet?.createdAt}</Text>
                            </View>

                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <Text style={{width: 150, fontSize: 12}}>Address</Text>
                                <Text style={{width: 40, fontSize: 12}}>:</Text>
                                <Text style={{fontSize: 12}}>{orderInfo?.address || (orderInfo?.lat && orderInfo?.lng)}</Text>
                            </View>

                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <Text style={{width: 150, fontSize: 12}}>Order Amount</Text>
                                <Text style={{width: 40, fontSize: 12}}>:</Text>
                                <Text style={{fontSize: 12}}>R{Number(orderDet?.orderAmount).toFixed(2)}</Text>
                            </View>

                    </View>

                    <View style={{width: '100%', paddingLeft: 15}}>
                    
                        <FlatList 
                            horizontal
                            data={orderProd}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={renderList} 
                        />
                    </View>


                </View>

                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Assign(ed) Driver</Text>
                    </View>

                    <View style={{marginTop: 15}}>

                        <View style={{marginBottom: 10}}>
                                                <Image style={{height: 80, width: 80, borderRadius: 10, borderWidth: 3, borderColor: mainColour }} source={{uri: orderInfo?.driverImage}} />
                        </View>
                        
                    </View>

                    <View style={{marginTop: 15, width: '100%', paddingLeft: 50}}> 
                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15}}>
                            <Text style={{width: 100, fontSize: 12}}>Name</Text>
                            <Text style={{width: 10, fontSize: 12}}>:</Text>
                            <Text style={{marginLeft: 30, fontSize: 12}}>{orderInfo?.driverName|| `-`}</Text>
                        </View>

                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15, marginTop: 10}}>
                            <Text style={{width: 100, fontSize: 12}}>Surname</Text>
                            <Text style={{width: 10, fontSize: 12}}>:</Text>
                            <Text style={{marginLeft: 30, fontSize: 12}}>{orderInfo?.driverSurname || `-`}</Text>
                        </View>

                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15, marginTop: 10}}>
                            <Text style={{width: 100, fontSize: 12}}>Car</Text>
                            <Text style={{width: 10, fontSize: 12}}>:</Text>
                            <Text style={{marginLeft: 30, fontSize: 12}}>{orderInfo?.car || `-`}</Text>
                        </View>

                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15, marginTop: 10}}>
                            <Text style={{width: 100, fontSize: 12}}>Model</Text>
                            <Text style={{width: 10, fontSize: 12}}>:</Text>
                            <Text style={{marginLeft: 30, fontSize: 12}}>{orderInfo?.model || `-`}</Text>
                        </View>
                    </View>




                </View>








                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Options</Text>
                    </View>


                    


                   {showCancelOption()}

                </View>
            </View>



        </View>
    </View>
  )
}

export default TopActivityCompleted