import { View, Text, FlatList, TouchableOpacity, Image, TextInput, ImageBackground } from 'react-native'
import React, {useState, useEffect} from 'react'
import { Entypo } from '@expo/vector-icons'; 
import { Ionicons } from '@expo/vector-icons'; 
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import uuid from 'react-native-uuid'; 
import { useNavigation } from '@react-navigation/native';
import { onCreateDriverList, onUpdateDriverList, onDeleteDriverList, onAddedDriver } from '../../graphql/subscriptions';
const DriversScreen = () => {

 const [addingProd, setAddingProd] = useState(false)

    const mainColour = '#FF4F00';

    const navigation = useNavigation();
    const [driverList, setDriverList] = useState([])
    const [updDriver, setUpdDrive] = useState([])

//     const driverList = [
//         {
//         id: 1,
//         name: 'Seluleko',
//         surname: 'Dlamini',
//         car: 'BMW',
//         model: 'X6',
//         plate: 'M27323'
//     },
//     {
//         id: 2,
//         name: 'Tiger',
//         surname: 'Nkosi',
//         car: 'Hyundai',
//         model: 'D500',
//         plate: 'M32323'
        
//     },
// ]



     async function fetchUsers() {

        const allTodos = await API.graphql({ query: queries.listDriverLists});

        const todos = allTodos.data.listDriverLists.items
        setDriverList(todos)


        console.log('items :', todos)

     }

     useEffect(() => {
      fetchUsers()

    }, [updDriver])



     const seperator = ()=> {
        return(
            <View style={{height: 1, backgroundColor: '#d1d1d1'}}/>
        )
    }

   

    const renderUsers = ({item})=> {

         const deleteDriver = async() => {

            const inputDelete = {
                id: item?.id,
            }

            const inputUserUpdate = {
                id: item?.userID,
                driver: false
            }

            await API.graphql({query: mutations.updateUserData, variables: {input: inputUserUpdate}}).then((e)=> {
                API.graphql({query: mutations.deleteDriverList, variables: {input: inputDelete}}).then((b)=> {
                    {}
                })
            })
 

        }


            return(
                <View  style={{marginLeft: 15, height: 150, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={{width: 80, height: 80, borderRadius: 80}} source={{uri: item?.image} || require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/>
                        <View style={{marginLeft: 25}}>
                            <Text>{item?.name} {item?.surname}</Text>
                            <Text style={{fontSize: 12}}>Car : {item?.car}</Text>
                            <Text style={{fontSize: 12}}>Model : {item?.model}</Text>
                            <Text style={{fontSize: 12}}>Plate : {item?.plate}</Text>
                        </View>
                        
                    </View>

                    <View style={{marginRight: 30, flexDirection: 'row', alignItems: 'center'}}>
                        <TouchableOpacity onPress={()=> navigation.navigate('Edit', {id: item?.id, name: item?.name, surname: item?.surname, image: item?.image, plate: item?.plate})} style={{height: 40, width: 80, borderRadius: 5, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{color: 'white'}}>Edit</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=> deleteDriver()} style={{height: 40, width: 80, marginLeft: 5, borderRadius: 5, borderColor: mainColour, borderWidth: 1,  alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{color: mainColour}}>Delete</Text>
                        </TouchableOpacity>
                    </View>
                    
                </View>
            )
        }

    
        let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onUpdateDriverList)).subscribe( {next: (daraa) => {
         setUpdDrive(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onDeleteDriverList)).subscribe( {next: (daraaa) => {
         setUpdDrive(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onAddedDriver)).subscribe( {next: (daraaaaa) => {
         setUpdDrive(daraaaaa)
     }, }) 

    }

    useEffect(() =>{
        setUpSus();

        return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };

    },[]);








  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{margin: 15, height: '10%', flexDirection: 'row'}}>
        <View>
               <TouchableOpacity onPress={()=> navigation.navigate('NewDriver')} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: mainColour}}>
                    <Ionicons name="add-circle-sharp" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Add Driver</Text> 
                </TouchableOpacity>
          </View> 

         
      </View>

       <View style={{width: '100%'}}>

            <View>
           <FlatList
                        scrollEnabled={true}
                        data={driverList}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={renderUsers} 
                        style={{height: 400}}
                        ItemSeparatorComponent={seperator}

                    />

                    </View>

                    </View>
    </View>
  )
}

export default DriversScreen