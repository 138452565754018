/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      name
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      post {
        id
        title
        blog {
          id
          name
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          title
          createdAt
          updatedAt
          blogPostsId
        }
        content
        createdAt
        updatedAt
        postCommentsId
      }
      nextToken
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      name
      surname
      phoneNumber
      location
      address
      email
      balance
      activeOrder
      lat
      lng
      profileComplete
      publish
      driver
      discountPercentage
      discountAmount
      discountQty
      createdAt
      updatedAt
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        phoneNumber
        location
        address
        email
        balance
        activeOrder
        lat
        lng
        profileComplete
        publish
        driver
        discountPercentage
        discountAmount
        discountQty
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      discount
      DaySpecial
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        images
        options
        categoryA
        categoryB
        avgRating
        ratings
        discount
        DaySpecial
        price
        oldPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductOD = /* GraphQL */ `
  query GetProductOD($id: ID!) {
    getProductOD(id: $id) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      promoCat
      discount
      promoted
      active
      price
      oldPrice
      filterCat
      filterCatB
      isle
      group
      category
      subCategory
      brand
      quantity
      available
      createdAt
      updatedAt
    }
  }
`;
export const listProductODS = /* GraphQL */ `
  query ListProductODS(
    $filter: ModelProductODFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductODS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        images
        options
        categoryA
        categoryB
        avgRating
        ratings
        DaySpecial
        promoCat
        discount
        promoted
        active
        price
        oldPrice
        filterCat
        filterCatB
        isle
        group
        category
        subCategory
        brand
        quantity
        available
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCartProduct = /* GraphQL */ `
  query GetCartProduct($id: ID!) {
    getCartProduct(id: $id) {
      id
      userSub
      quantity
      option
      title
      price
      oldPrice
      image
      productID
      createdAt
      updatedAt
    }
  }
`;
export const listCartProducts = /* GraphQL */ `
  query ListCartProducts(
    $filter: ModelCartProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        quantity
        option
        title
        price
        oldPrice
        image
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      quantity
      option
      price
      image
      title
      titilet
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        option
        price
        image
        title
        titilet
        productID
        orderID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      country
      city
      amount
      qty
      lat
      lng
      active
      driverName
      driverPlate
      driverCar
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        orderAmount
        paymentStatus
        deliveryStatus
        fullName
        phoneNumber
        address
        country
        city
        amount
        qty
        lat
        lng
        active
        driverName
        driverPlate
        driverCar
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageList = /* GraphQL */ `
  query GetMessageList($id: ID!) {
    getMessageList(id: $id) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const listMessageLists = /* GraphQL */ `
  query ListMessageLists(
    $filter: ModelMessageListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        message
        backgroundColor
        letter
        author
        read
        date
        time
        userSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsList = /* GraphQL */ `
  query GetNotificationsList($id: ID!) {
    getNotificationsList(id: $id) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationsLists = /* GraphQL */ `
  query ListNotificationsLists(
    $filter: ModelNotificationsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        message
        backgroundColor
        letter
        author
        read
        date
        time
        userSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDriverList = /* GraphQL */ `
  query GetDriverList($id: ID!) {
    getDriverList(id: $id) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const listDriverLists = /* GraphQL */ `
  query ListDriverLists(
    $filter: ModelDriverListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDriverLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        image
        active
        lat
        lng
        userID
        car
        model
        plate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategoryList = /* GraphQL */ `
  query GetCategoryList($id: ID!) {
    getCategoryList(id: $id) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const listCategoryLists = /* GraphQL */ `
  query ListCategoryLists(
    $filter: ModelCategoryListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        level
        parentID
        parentName
        hasChild
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBannerList = /* GraphQL */ `
  query GetBannerList($id: ID!) {
    getBannerList(id: $id) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const listBannerLists = /* GraphQL */ `
  query ListBannerLists(
    $filter: ModelBannerListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        titletwo
        image
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReturnProduct = /* GraphQL */ `
  query GetReturnProduct($id: ID!) {
    getReturnProduct(id: $id) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listReturnProducts = /* GraphQL */ `
  query ListReturnProducts(
    $filter: ModelReturnProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReturnProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        productID
        orderID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderReport = /* GraphQL */ `
  query GetOrderReport($id: ID!) {
    getOrderReport(id: $id) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listOrderReports = /* GraphQL */ `
  query ListOrderReports(
    $filter: ModelOrderReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        orderID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Lat
        Lng
        Address
        balance
        email
        phoneNumber
        createdAt
        updatedAt
        logo
        busName
        website
        busReg
        social
        category
        ownerName
        ownerSurname
        ownerEmail
        carryStock
        physStore
      }
      nextToken
    }
  }
`;
export const getShopRequest = /* GraphQL */ `
  query GetShopRequest($id: ID!) {
    getShopRequest(id: $id) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const listShopRequests = /* GraphQL */ `
  query ListShopRequests(
    $filter: ModelShopRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        busName
        website
        busReg
        social
        category
        ownerName
        ownerSurname
        ownerEmail
        comments
        status
        carryStock
        physStore
        supplyRet
        unique
        brands
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
