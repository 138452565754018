import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import UsersScreen from '../Screens/UsersScreen';
import UserDetails from '../Screens/UsersScreen/UserDetails';
import TopActivity from '../Screens/UsersScreen/TopActivity';
// import StockMain from '../Screens/StockScreen.js/StockMain';
import OrdersScreen from '../Screens/OrdersScreen/OrdersScreen';
import TopActivityCompleted from '../Screens/OrdersScreen/TopActivityCompleted';
import HomeScreen from '../Screens/HomeScreen/HomeScreen';
// import Banners from '../Screens/HomeScreen/Banners';
// import AddBanner from '../Screens/HomeScreen/AddBanner';

const Stack = createStackNavigator();

const HomeStack = () => {

    return (
        <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >

                <Stack.Screen 
                
                name="Main">
                    {() => <HomeScreen/>}
                </Stack.Screen>
                {/* <Stack.Screen component={UserDetails} name="UserDetails" options={{headerShown: false}} />*/}
                {/* <Stack.Screen component={Banners} name="Banners" options={{headerShown: false}} /> 
                <Stack.Screen component={AddBanner} name="AddBanner" options={{headerShown: false}} />  */}

            </Stack.Navigator>
        </View>
    );

};

export default HomeStack;